/* Copyright 2014 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  addLinkAttributes,
  DOMSVGFactory,
  getFilenameFromUrl,
  LinkTarget,
  PDFDateString,
} from "./display_utils.js";
import {
  AnnotationBorderStyleType,
  AnnotationType,
  stringToPDFString,
  unreachable,
  Util,
  warn,
  stringifyDate
} from "../shared/util.js";
import SignaturePad from "../shared/signature_pad.min.js";

/**
 * @typedef {Object} AnnotationElementParameters
 * @property {Object} data
 * @property {HTMLDivElement} layer
 * @property {PDFPage} page
 * @property {PageViewport} viewport
 * @property {IPDFLinkService} linkService
 * @property {DownloadManager} downloadManager
 * @property {string} [imageResourcesPath] - Path for image resources, mainly
 *   for annotation icons. Include trailing slash.
 * @property {boolean} renderInteractiveForms
 * @property {Object} svgFactory
 * @property {EventBus} eventBus - The application event bus.
 */

class AnnotationElementFactory {
  /**
   * @param {AnnotationElementParameters} parameters
   * @returns {AnnotationElement}
   */
  static create(parameters) {
    const subtype = parameters.data.annotationType;

    switch (subtype) {
      case AnnotationType.LINK:
        return new LinkAnnotationElement(parameters);

      case AnnotationType.TEXT:
        return new TextAnnotationElement(parameters);

      case AnnotationType.WIDGET:
        const fieldType = parameters.data.fieldType;

        switch (fieldType) {
          case "Tx":
            return new TextWidgetAnnotationElement(parameters);
          case "Btn":
            if (parameters.data.radioButton) {
              return new RadioButtonWidgetAnnotationElement(parameters);
            } else if (parameters.data.checkBox) {
              return new CheckboxWidgetAnnotationElement(parameters);
            }
            return new PushButtonWidgetAnnotationElement(parameters);
          case "Ch":
            return new ChoiceWidgetAnnotationElement(parameters);
          case "Sig":
            return new SignatureWidgetAnnotationElement(parameters);
          case "Drawing":
            return new DrawingAnnotationElement(parameters);
        }
        return new WidgetAnnotationElement(parameters);

      case AnnotationType.POPUP:
        return new PopupAnnotationElement(parameters);

      case AnnotationType.FREETEXT:
        return new FreeTextAnnotationElement(parameters);

      case AnnotationType.LINE:
        return new LineAnnotationElement(parameters);

      case AnnotationType.SQUARE:
        return new SquareAnnotationElement(parameters);

      case AnnotationType.CIRCLE:
        return new CircleAnnotationElement(parameters);

      case AnnotationType.POLYLINE:
        return new PolylineAnnotationElement(parameters);

      case AnnotationType.CARET:
        return new CaretAnnotationElement(parameters);

      case AnnotationType.INK:
        return new InkAnnotationElement(parameters);

      case AnnotationType.POLYGON:
        return new PolygonAnnotationElement(parameters);

      case AnnotationType.HIGHLIGHT:
        return new HighlightAnnotationElement(parameters);

      case AnnotationType.UNDERLINE:
        return new UnderlineAnnotationElement(parameters);

      case AnnotationType.SQUIGGLY:
        return new SquigglyAnnotationElement(parameters);

      case AnnotationType.STRIKEOUT:
        return new StrikeOutAnnotationElement(parameters);

      case AnnotationType.STAMP:
        return new StampAnnotationElement(parameters);

      case AnnotationType.FILEATTACHMENT:
        return new FileAttachmentAnnotationElement(parameters);

      default:
        return new AnnotationElement(parameters);
    }
  }
}

class AnnotationElement {
  constructor(parameters, isRenderable = false, ignoreBorder = false) {
    this.isRenderable = isRenderable;
    this.data = parameters.data;
    this.layer = parameters.layer;
    this.page = parameters.page;
    this.viewport = parameters.viewport;
    this.linkService = parameters.linkService;
    this.downloadManager = parameters.downloadManager;
    this.imageResourcesPath = parameters.imageResourcesPath;
    this.renderInteractiveForms = parameters.renderInteractiveForms;
    this.svgFactory = parameters.svgFactory;
    this.eventBus = parameters.eventBus;

    if (isRenderable) {
      this.container = this._createContainer(ignoreBorder);
    }
  }

  /**
   * Create an empty container for the annotation's HTML element.
   *
   * @private
   * @param {boolean} ignoreBorder
   * @memberof AnnotationElement
   * @returns {HTMLSectionElement}
   */
  _createContainer(ignoreBorder = false) {
    const data = this.data,
      page = this.page,
      viewport = this.viewport;
    const container = document.createElement("section");
    let width = data.rect[2] - data.rect[0];
    let height = data.rect[3] - data.rect[1];

    container.setAttribute("data-annotation-id", data.id);

    // Do *not* modify `data.rect`, since that will corrupt the annotation
    // position on subsequent calls to `_createContainer` (see issue 6804).
    const rect = Util.normalizeRect([
      data.rect[0],
      page.view[3] - data.rect[1] + page.view[1],
      data.rect[2],
      page.view[3] - data.rect[3] + page.view[1],
    ]);

    container.style.transform = `matrix(${viewport.transform.join(",")})`;
    container.style.transformOrigin = `-${rect[0]}px -${rect[1]}px`;

    if (!ignoreBorder && data.borderStyle.width > 0) {
      container.style.borderWidth = `${data.borderStyle.width}px`;
      if (data.borderStyle.style !== AnnotationBorderStyleType.UNDERLINE) {
        // Underline styles only have a bottom border, so we do not need
        // to adjust for all borders. This yields a similar result as
        // Adobe Acrobat/Reader.
        width = width - 2 * data.borderStyle.width;
        height = height - 2 * data.borderStyle.width;
      }

      const horizontalRadius = data.borderStyle.horizontalCornerRadius;
      const verticalRadius = data.borderStyle.verticalCornerRadius;
      if (horizontalRadius > 0 || verticalRadius > 0) {
        const radius = `${horizontalRadius}px / ${verticalRadius}px`;
        container.style.borderRadius = radius;
      }

      switch (data.borderStyle.style) {
        case AnnotationBorderStyleType.SOLID:
          container.style.borderStyle = "solid";
          break;

        case AnnotationBorderStyleType.DASHED:
          container.style.borderStyle = "dashed";
          break;

        case AnnotationBorderStyleType.BEVELED:
          warn("Unimplemented border style: beveled");
          break;

        case AnnotationBorderStyleType.INSET:
          warn("Unimplemented border style: inset");
          break;

        case AnnotationBorderStyleType.UNDERLINE:
          container.style.borderBottomStyle = "solid";
          break;

        default:
          break;
      }

      if (data.color) {
        container.style.borderColor = Util.makeCssRgb(
          data.color[0] | 0,
          data.color[1] | 0,
          data.color[2] | 0
        );
      } else {
        // Transparent (invisible) border, so do not draw it at all.
        container.style.borderWidth = 0;
      }
    }

    container.style.left = `${rect[0]}px`;
    container.style.top = `${rect[1]}px`;
    container.style.width = `${width}px`;
    container.style.height = `${height}px`;
    container.style.zIndex = "10";
    return container;
  }

  /**
   * Create a popup for the annotation's HTML element. This is used for
   * annotations that do not have a Popup entry in the dictionary, but
   * are of a type that works with popups (such as Highlight annotations).
   *
   * @private
   * @param {HTMLSectionElement} container
   * @param {HTMLDivElement|HTMLImageElement|null} trigger
   * @param {Object} data
   * @memberof AnnotationElement
   */
  _createPopup(container, trigger, data) {
    // If no trigger element is specified, create it.
    if (!trigger) {
      trigger = document.createElement("div");
      trigger.style.height = container.style.height;
      trigger.style.width = container.style.width;
      container.appendChild(trigger);
    }

    const popupElement = new PopupElement({
      container,
      trigger,
      color: data.color,
      title: data.title,
      modificationDate: data.modificationDate,
      contents: data.contents,
      hideWrapper: true,
    });
    const popup = popupElement.render();

    // Position the popup next to the annotation's container.
    popup.style.left = container.style.width;

    container.appendChild(popup);
  }

  /**
   * Render the annotation's HTML element in the empty container.
   *
   * @public
   * @memberof AnnotationElement
   */
  render() {
    unreachable("Abstract method `AnnotationElement.render` called");
  }
}

class LinkAnnotationElement extends AnnotationElement {
  constructor(parameters) {
    const isRenderable = !!(
      parameters.data.url ||
      parameters.data.dest ||
      parameters.data.action
    );
    super(parameters, isRenderable);
  }

  /**
   * Render the link annotation's HTML element in the empty container.
   *
   * @public
   * @memberof LinkAnnotationElement
   * @returns {HTMLSectionElement}
   */
  render() {
    this.container.className = "linkAnnotation";

    const { data, linkService } = this;
    const link = document.createElement("a");

    if (data.url) {
      addLinkAttributes(link, {
        url: data.url,
        target: data.newWindow
          ? LinkTarget.BLANK
          : linkService.externalLinkTarget,
        rel: linkService.externalLinkRel,
        enabled: linkService.externalLinkEnabled,
      });
    } else if (data.action) {
      this._bindNamedAction(link, data.action);
    } else {
      this._bindLink(link, data.dest);
    }

    this.container.appendChild(link);
    return this.container;
  }

  /**
   * Bind internal links to the link element.
   *
   * @private
   * @param {Object} link
   * @param {Object} destination
   * @memberof LinkAnnotationElement
   */
  _bindLink(link, destination) {
    link.href = this.linkService.getDestinationHash(destination);
    link.onclick = () => {
      if (destination) {
        this.linkService.navigateTo(destination);
      }
      return false;
    };
    if (destination) {
      link.className = "internalLink";
    }
  }

  /**
   * Bind named actions to the link element.
   *
   * @private
   * @param {Object} link
   * @param {Object} action
   * @memberof LinkAnnotationElement
   */
  _bindNamedAction(link, action) {
    link.href = this.linkService.getAnchorUrl("");
    link.onclick = () => {
      this.linkService.executeNamedAction(action);
      return false;
    };
    link.className = "internalLink";
  }
}

class TextAnnotationElement extends AnnotationElement {
  constructor(parameters) {
    const isRenderable = !!(
      parameters.data.hasPopup ||
      parameters.data.title ||
      parameters.data.contents
    );
    super(parameters, isRenderable);
  }

  /**
   * Render the text annotation's HTML element in the empty container.
   *
   * @public
   * @memberof TextAnnotationElement
   * @returns {HTMLSectionElement}
   */
  render() {
    this.container.className = "textAnnotation";

    const image = document.createElement("img");
    image.style.height = this.container.style.height;
    image.style.width = this.container.style.width;
    image.src =
      this.imageResourcesPath +
      "annotation-" +
      this.data.name.toLowerCase() +
      ".svg";
    image.alt = "[{{type}} Annotation]";
    image.dataset.l10nId = "text_annotation_type";
    image.dataset.l10nArgs = JSON.stringify({ type: this.data.name });

    if (!this.data.hasPopup) {
      this._createPopup(this.container, image, this.data);
    }

    this.container.appendChild(image);
    return this.container;
  }
}

class WidgetAnnotationElement extends AnnotationElement {
  /**
   * Render the widget annotation's HTML element in the empty container.
   *
   * @public
   * @memberof WidgetAnnotationElement
   * @returns {HTMLSectionElement}
   */

  render() {
    // Show only the container for unsupported field types.
    this.container.style.zIndex = 10;
    return this.container;
  }

  // This applies to textboxes and dropdowns
  applyRequiredStyling(element) {
    const fieldDTO = (window.GXData || []).find(field => field.name === this.data.fieldName);

    if (window.parent.isPatientView) {
      // only patients will have non-signature required fields
      this.ChangeElementValidation(element, false);
      if (fieldDTO && fieldDTO.required && !element.value) {
        this.ChangeElementValidation(element, true);
      }
      else if (!fieldDTO && this.data.required && !element.value) {
        this.ChangeElementValidation(element, true);
      }
    }
    this.ChangeAllRequiredValidation(element, element.value);
  }

  applyRequiredStylingForRadio(element, dataValue) {
    const fieldDTO = (window.GXData || []).find(field => field.name === this.data.fieldName);

    if (window.parent.isPatientView) {
      // only patients will have non-signature required fields
      this.ChangeElementValidation(element, false);
      if (fieldDTO && fieldDTO.required && !dataValue) {
        this.ChangeElementValidation(element, true);
      }
      else if (this.data.required && !dataValue) {
        this.ChangeElementValidation(element, true);
      }
    }
    this.ChangeAllRequiredValidation(element, dataValue);
  }

  applyRequiredStylingForDrawnSignature(element) {
    this.ChangeElementValidation(element, false);
    if (element.value === 'Click to Sign') {
      // Does it exist in the GXData?
      const fieldDTO = (window.GXData || []).find(element => element.name === this.data.fieldName);

      // If it does't exist in the GXData and this is a patient, then make it required.  We will
      // assume a signature not modified from the original PDF as belonging to the patient
      if (!fieldDTO && this.data.required && window.parent.isPatientView) {
        this.ChangeElementValidation(element, true);
        this.ChangeAllRequiredValidation(element, element.value, true);
        return;
      }

      // If it does exist in the GXData and this is a patient signature and it's required from the GXData, then make it required
      // GXData takes priority over the PDF signature required setting
      if (fieldDTO && fieldDTO.required && fieldDTO.signatureFor === 'patient' && window.parent.isPatientView) {
        this.ChangeElementValidation(element, true);
        this.ChangeAllRequiredValidation(element, element.value, true);
        return;
      }

      // If this was for a patient filling this out, then we shouldn't continue
      if (window.parent.isPatientView) {
        return;
      }

      // At this point, it may be a staff signature.
      if (fieldDTO && fieldDTO.required && fieldDTO.signatureFor === 'staff') {

        // It's a required staff signature, but is this signature assigned to the logged in user?
        // There may be other required signatures but not for this logged in clinician
        if (window.parent.RequiredClinicianSignatures && window.parent.RequiredClinicianSignatures.includes(fieldDTO.name)) {
          this.ChangeElementValidation(element, true);
          this.ChangeAllRequiredValidation(element, element.value, true);
          return;
        }
      }
    }
    this.ChangeAllRequiredValidation(element, element.value, true);
  }

  ChangeElementValidation(element, isRequired) {
    const hasElementsBefore = window.requiredElementsForCurrentView.length > 0;

    if (isRequired) {
      if (!window.requiredElementsForCurrentView.includes(element.name)) {
        window.requiredElementsForCurrentView.push(element.name);
      }
      element.classList.add("required");
    } else {
      if (window.requiredElementsForCurrentView.includes(element.name)) {
        window.requiredElementsForCurrentView.splice(window.requiredElementsForCurrentView.indexOf(element.name), 1);
      }
      element.classList.remove("required");
    }

    const hasElementsAfter = window.requiredElementsForCurrentView.length > 0;
    if (hasElementsBefore !== hasElementsAfter) {
      this.eventBus.dispatch("validationchanged", {
        source: { isValid: !hasElementsAfter }
      });
    }
  }

  // This will keep track of all required fields regardless of which view the user is filling this out in.
  ChangeAllRequiredValidation(element, value, isSignature = false) {
    const fieldDTO = (window.GXData || []).find(element => element.name === this.data.fieldName);

    // We only want to look at required fields for other people, not this current user
    let allOtherRequiredFields = window.allRequiredElements;
    for (let i = 0; i < window.requiredElementsForCurrentView.length; i++) {
      if (allOtherRequiredFields.includes(window.requiredElementsForCurrentView[i])) {
        allOtherRequiredFields.splice(allOtherRequiredFields.indexOf(window.requiredElementsForCurrentView[i]), 1);
      }
    }

    const hasElementsBefore = allOtherRequiredFields.length > 0;

    // remove this element for starters.  Then prove if it needs to be added
    if (window.allRequiredElements.includes(element.name)) {
      window.allRequiredElements.splice(window.allRequiredElements.indexOf(element.name), 1);
    }

    // signatures
    if (isSignature) {
      if (!fieldDTO && this.data.required && value === 'Click to Sign') {
        window.allRequiredElements.push(element.name);
      } else if (fieldDTO && fieldDTO.required && value === 'Click to Sign') {
        window.allRequiredElements.push(element.name);
      }
    } else {
      // Non-signatures
      if (fieldDTO && fieldDTO.required && !value) {
        // If the GX designer overrode the PDF required, then make it required
        window.allRequiredElements.push(element.name);
      // The GX designer did not override this required field, so use the pdf
      } else if (this.data.required && !value) {
        window.allRequiredElements.push(element.name);
      }
    }

    allOtherRequiredFields = window.allRequiredElements;
    for (let i = 0; i < window.requiredElementsForCurrentView.length; i++) {
      if (allOtherRequiredFields.includes(window.requiredElementsForCurrentView[i])) {
        allOtherRequiredFields.splice(allOtherRequiredFields.indexOf(window.requiredElementsForCurrentView[i]), 1);
      }
    }

    const hasElementsAfter = allOtherRequiredFields.length > 0;

    // Notify the parent anytine the entire form toggles from valid to invalid or vice versa
    if (hasElementsBefore !== hasElementsAfter) {
      this.eventBus.dispatch("allrequiredfieldschanged", {
        source: { isEntireFormValid: !hasElementsAfter }
      });
    }
  }
}

class TextWidgetAnnotationElement extends WidgetAnnotationElement {
  constructor(parameters) {
    const isRenderable =
      parameters.renderInteractiveForms ||
      (!parameters.data.hasAppearance && !!parameters.data.fieldValue);
    super(parameters, isRenderable);

    this.standard14Fonts = {
      Helv: {
        name: "Helvitica",
        fontFamily: "Helvitica",
        bold: false,
        italic: false,
        fallback: "sans-serif",
      },
      HeBO: {
        name: "Helvitica-BoldOblique",
        fontFamily: "Helvitica",
        bold: true,
        italic: true,
        fallback: "sans-serif",
      },
      HeBo: {
        name: "Helvitica-Bold",
        fontFamily: "Helvitica",
        bold: true,
        italic: false,
        fallback: "sans-serif",
      },
      HeOb: {
        name: "Helvitica-Oblique",
        fontFamily: "Helvitica",
        bold: false,
        italic: true,
        fallback: "sans-serif",
      },
      Cour: {
        name: "Courier",
        fontFamily: "Courier",
        bold: false,
        italic: false,
        fallback: "monospace",
      },
      CoBO: {
        name: "Courier-BoldOblique",
        fontFamily: "Courier",
        bold: true,
        italic: true,
        fallback: "monospace",
      },
      CoBo: {
        name: "Courier-Bold",
        fontFamily: "Courier",
        bold: true,
        italic: false,
        fallback: "monospace",
      },
      CoOb: {
        name: "Courier-Oblique",
        fontFamily: "Courier",
        bold: false,
        italic: true,
        fallback: "monospace",
      },
      TiRo: {
        name: "Times-Roman",
        fontFamily: '"Times New Roman"',
        bold: false,
        italic: false,
        fallback: "Times, serif",
      },
      TiBI: {
        name: "Times-BoldItalic",
        fontFamily: '"Times New Roman"',
        bold: true,
        italic: true,
        fallback: "Times, serif",
      },
      TiBo: {
        name: "Times-Bold",
        fontFamily: '"Times New Roman"',
        bold: true,
        italic: false,
        fallback: "Times, serif",
      },
      TiIt: {
        name: "Times-Italic",
        fontFamily: '"Times New Roman"',
        bold: false,
        italic: true,
        fallback: "Times, serif",
      },
      Symb: {
        name: "Symbol",
        fontFamily: "Symbol",
        bold: false,
        italic: false,
        fallback: "",
      },
      Zabd: {
        name: "ZapfDingbats",
        fontFamily: "ZapfDingbats",
        bold: false,
        italic: false,
        fallback: "",
      },
    };
  }

  /**
   * Render the text widget annotation's HTML element in the empty container.
   *
   * @public
   * @memberof TextWidgetAnnotationElement
   * @returns {HTMLSectionElement}
   */
  render() {
    const gxElement = this.data.GXField || { };
    const TEXT_ALIGNMENT = ["left", "center", "right"];
    this.container.className = "textWidgetAnnotation";

    let element = null;
    if (this.renderInteractiveForms) {
      // NOTE: We cannot set the values using `element.value` below, since it
      //       prevents the AnnotationLayer rasterizer in `test/driver.js`
      //       from parsing the elements correctly for the reference tests.
      if (this.data.multiLine) {
        element = document.createElement("textarea");
        element.textContent = this.data.fieldValue;
      } else {
        element = document.createElement("input");

        // Additional formatting is optional, an inferred by looking at /AA JS
        const formatting = this.data.additionalFormatting;
        if (formatting) {
          switch (formatting.type) {
            case "date":
              element.type = "date";
              break;
            case "time":
              element.type = "time";
              break;
            case "date-time":
              element.type = "datetime-local";
              break;
            case "currency": // fall-through
            // If we wanted to add the currency symbol
            // See: https://codepen.io/tutsplus/pen/WxpNRJ
            case "numeric":
              element.type = "number";
              if (formatting.min !== undefined) {
                element.min = formatting.min;
              }
              if (formatting.max !== undefined) {
                element.max = formatting.max;
              }
              break;
            case "phone":
              element.type = "tel";
              break;
            default:
              element.type = "text";
          }
        }

        element.setAttribute("value", this.data.fieldValue);
      }

      element.name = this.data.fieldName;
      element.autocomplete = "off";
      const annotState = window.AnnotationState[element.name];
      const isLocked = annotState && annotState.IsLocked;
      if (isLocked) {
        element.disabled = true;
      }
      else if (Object.prototype.hasOwnProperty.call(gxElement, 'readonly')) {
        element.disabled = gxElement.readonly;
      }
      else {
        element.disabled = this.data.readOnly;
      }

      // Trigger change event - The viewer is responsible for state management
      element.onchange = () =>
        this.eventBus.dispatch("fieldchanged", {
          source: this,
        });

      if (this.data.maxLen !== null) {
        element.maxLength = this.data.maxLen;
      }

      if (this.data.comb) {
        const fieldWidth = this.data.rect[2] - this.data.rect[0];
        const combWidth = fieldWidth / this.data.maxLen;

        element.classList.add("comb");
        element.style.letterSpacing = `calc(${combWidth}px - 1ch)`;
      }

      const da = this._parseDefaultAppearance(this.data.defaultAppearance);
      this._setTextStyleByAppearance(element, da);
    } else {
      element = document.createElement("div");
      element.textContent = this.data.fieldValue;
      element.style.verticalAlign = "middle";
      element.style.display = "table-cell";

      let font = null;
      if (
        this.data.fontRefName &&
        this.page.commonObjs.has(this.data.fontRefName)
      ) {
        font = this.page.commonObjs.get(this.data.fontRefName);
      }
      this._setTextStyle(element, font);
    }

    if (this.data.textAlignment !== null) {
      element.style.textAlign = TEXT_ALIGNMENT[this.data.textAlignment];
    }

    let that = this;
    element.onblur = function() {
        that.applyRequiredStyling(element);
    }
    this.container.appendChild(element);
    this.applyRequiredStyling(element);
    return this.container;
  }

  /**
   * Apply text styles to the text in the element.
   *
   * @private
   * @param {HTMLDivElement} element
   * @param {Object} font
   * @memberof TextWidgetAnnotationElement
   */
  _setTextStyle(element, font) {
    // TODO: This duplicates some of the logic in CanvasGraphics.setFont().
    const style = element.style;
    style.fontSize = `${this.data.fontSize}px`;
    style.direction = this.data.fontDirection < 0 ? "rtl" : "ltr";

    if (!font) {
      return;
    }

    let bold = "normal";
    if (font.black) {
      bold = "900";
    } else if (font.bold) {
      bold = "bold";
    }
    style.fontWeight = bold;
    style.fontStyle = font.italic ? "italic" : "normal";

    // Use a reasonable default font if the font doesn't specify a fallback.
    const fontFamily = font.loadedName ? `"${font.loadedName}", ` : "";
    const fallbackName = font.fallbackName || "Helvetica, sans-serif";
    style.fontFamily = fontFamily + fallbackName;
  }

  _setTextStyleByAppearance(element, appearance) {
    const style = element.style;
    style.fontSize = `${appearance.size}px`;
    style.direction = this.data.fontDirection < 0 ? "rtl" : "ltr";

    style.fontWeight = appearance.fontDetails.bold ? "bold" : "normal";
    style.fontStyle = appearance.fontDetails.italic ? "italic" : "normal";

    style.fontFamily = appearance.fontDetails.fallback
      ? `${appearance.fontDetails.fontFamily}, ${appearance.fontDetails.fallback}`
      : appearance.fontDetails.fontFamily;
  }

  _parseDefaultAppearance(da) {
    const stack = (da || "").split(" ");

    const appearance = {
      fontDetails: this.standard14Fonts.Helv,
      size: 12,
    };

    // Below, convert GrayScale, RGB, and CMYK to hex rgb for adding color detail
    // const rgbToHex = (r, g, b) => '#' + [r, g, b]
    //  .map(x => x.toString(16).padStart(2, '0')).join('');

    while (stack.length) {
      const token = stack.pop();
      switch (token) {
        case "Tf": {
          if (stack.length > 2) {
            // If size is 0, default
            const size = Number(stack.pop());
            if (size) {
              appearance.size = size;
            }

            const fontCode = stack.pop().replace(/^\/+/, "");
            const fontDetails = this.standard14Fonts[fontCode];
            if (fontDetails) {
              appearance.fontDetails = fontDetails;
            }
          }
          break;
        }
        // case "g": GrayScale
        // case "rg": RGB
        // case "k": CMYK
      }
    }

    return appearance;
  }
}

class CheckboxWidgetAnnotationElement extends WidgetAnnotationElement {
  constructor(parameters) {
    super(parameters, parameters.renderInteractiveForms);
  }

  /**
   * Render the checkbox widget annotation's HTML element
   * in the empty container.
   *
   * @public
   * @memberof CheckboxWidgetAnnotationElement
   * @returns {HTMLSectionElement}
   */
  render() {
    const gxElement = this.data.GXField || { };

    this.container.className = "buttonWidgetAnnotation checkBox";
    const element = document.createElement("input");
    element.type = "checkbox";
    element.name = this.data.fieldName;
    element.value = this.data.exportValue;
    element.autocomplete = "off";

    const annotState = window.AnnotationState[element.name];
    const isLocked = annotState && annotState.IsLocked;
    if (isLocked) {
      element.disabled = true;
    }
    else if (Object.prototype.hasOwnProperty.call(gxElement, 'readonly')) {
      element.disabled = gxElement.readonly;
    }
    else {
      element.disabled = this.data.readOnly;
    }

    if (this.data.fieldValue && this.data.fieldValue !== "Off") {
      element.setAttribute("checked", true);
    }

    // Trigger change event - The viewer is responsible for state management
    element.onclick = () =>
      this.eventBus.dispatch("fieldchanged", {
        source: this,
      });

    this.container.appendChild(element);
    return this.container;
  }
}

class RadioButtonWidgetAnnotationElement extends WidgetAnnotationElement {
  constructor(parameters) {
    super(parameters, parameters.renderInteractiveForms);
  }

  /**
   * Render the radio button widget annotation's HTML element
   * in the empty container.
   *
   * @public
   * @memberof RadioButtonWidgetAnnotationElement
   * @returns {HTMLSectionElement}
   */
  render() {
    const gxElement = this.data.GXField || { };
    this.container.className = "buttonWidgetAnnotation radioButton";

    const element = document.createElement("input");
    element.type = "radio";
    element.name = this.data.fieldName;
    element.value = this.data.buttonValue;
    element.autocomplete = "off";

    const annotState = window.AnnotationState[element.name];
    const isLocked = annotState && annotState.IsLocked;
    if (isLocked) {
      element.disabled = true;
    }
    else if (Object.prototype.hasOwnProperty.call(gxElement, 'readonly')) {
      element.disabled = gxElement.readonly;
    }
    else {
      element.disabled = this.data.readOnly;
    }

    if (this.data.fieldValue === this.data.buttonValue) {
      element.setAttribute("checked", true);
    }

    // Trigger change event - The viewer is responsible for state management
    element.onclick = () => {
      this.eventBus.dispatch("fieldchanged", {
        source: this,
      });
      let options = document.getElementsByName(this.data.fieldName);
      for (let i = 0; i < options.length; i++) {
        this.ChangeElementValidation(options[i], false);
        this.ChangeAllRequiredValidation(options[i], this.data.fieldValue);
      }
    }

    this.container.appendChild(element);
    this.applyRequiredStylingForRadio(element, this.data.fieldValue);

    return this.container;
  }
}

class PushButtonWidgetAnnotationElement extends LinkAnnotationElement {
  /**
   * Render the push button widget annotation's HTML element
   * in the empty container.
   *
   * @public
   * @memberof PushButtonWidgetAnnotationElement
   * @returns {HTMLSectionElement}
   */
  render() {
    // The rendering and functionality of a push button widget annotation is
    // equal to that of a link annotation, but may have more functionality, such
    // as performing actions on form fields (resetting, submitting, et cetera).
    const container = super.render();
    container.className = "buttonWidgetAnnotation pushButton";
    return container;
  }
}

class ChoiceWidgetAnnotationElement extends WidgetAnnotationElement {
  constructor(parameters) {
    super(parameters, parameters.renderInteractiveForms);
  }

  /**
   * Render the choice widget annotation's HTML element in the empty
   * container.
   *
   * @public
   * @memberof ChoiceWidgetAnnotationElement
   * @returns {HTMLSectionElement}
   */
  render() {
    const ref = this;

    // Listen for an event the tells the pdf viewer the underlying config has changed
    this.eventBus.on("updateconfigs", function (updatedFieldConfigs) {
      const field = updatedFieldConfigs.find(
        element => element.name === ref.data.fieldName
      );
      if (field) {
        const index = (window.GXData || []).findIndex(
          element => element.name === ref.data.fieldName
        );
        if (index >= 0) {
          // Only update the data and re-render this element if the config was passed in
          window.GXData[index] = field;

          ref.renderElement();
        }
      }
    });

    return this.renderElement();
  }

  renderElement() {
    this.container.className = "choiceWidgetAnnotation";

    // Get the element from the GX Data
    const gxElement = this.data.GXField || { };

    if (gxElement.dropDownFilter || gxElement.dropDownMultiSelect) {
      this.renderDropDownWithFilter(gxElement);
    }
    else {
      this.renderDropDown(gxElement);
    }
    return this.container;
  }

  /**
   * Build a filter-as-you-type dropdown widget
   *
   * @param gxElement {Object} - The GX FieldDTO for this widget
   */
  renderDropDownWithFilter(gxElement = { }) {
    // Since this is to include auto-filter, we will create an input so the user can type
    // along with a data list.  Once the user leaves the DDL, we will verify their selection
    // is among the options

    let options = gxElement.options || this.data.options;

    let inputElement;
    let dataListElement;
    const elementSearch = document.getElementsByName(this.data.fieldName);

    // If we've already made the element, clear the options so we can rebuild it
    if (elementSearch && elementSearch.length > 0) {
      inputElement = elementSearch[0];
      dataListElement = document.getElementById(this.data.fieldName + '_7f6a3179-49a1-407f-aa52-b807e2db1110');
      dataListElement.innerHTML = '';
    }

    // If the element does not exist, build it
    else {
      inputElement = document.createElement("input");
      inputElement.type = "text";
      inputElement.name = this.data.fieldName;
      inputElement.autocomplete = "off";
      inputElement.setAttribute("list", this.data.fieldName + "_7f6a3179-49a1-407f-aa52-b807e2db1110");
      inputElement.className = "comboBox";
      inputElement.value = this.data.fieldValue || '';

      let that = this;
      inputElement.onchange = () => {
        that.applyRequiredStyling(inputElement);
        this.eventBus.dispatch("fieldchanged", {
          source: this,
        });
      }

      // Reset button
      const resetButton = document.createElement("button");
      resetButton.type = "button"
      resetButton.className = 'resetButton';
      resetButton.style.top = -parseInt(this.container.style.height, 10)/2 - 12 + "px";

      resetButton.onmouseover = () => {
        if (inputElement.value) {
          resetButton.innerHTML = 'X';
        } else {
          resetButton.innerHTML = '';
        }
      }

      resetButton.onmouseleave = () => {
        resetButton.innerHTML = '';
      }

      resetButton.onclick = () => {
        inputElement.value = '';
        resetButton.innerHTML = '';
      }

      // Hook up inpueElement events
      inputElement.onmouseover = () => {
        if (inputElement.value) {
          resetButton.innerHTML = 'X';
        } else {
          resetButton.innerHTML = '';
        }
      }

      inputElement.onmouseleave = () => {
        resetButton.innerHTML = '';
      }

      inputElement.onblur = () => {
        // check to make sure the value entered matches one of the options
        // if the option case is different, then change it to the proper case
        // update the value in state if found, and clear if not
        if (inputElement.value) {
          let isValid = false;
          for (const option of options) {
            if (option.text.toLowerCase().startsWith(inputElement.value.toLowerCase()) || option.value.toLowerCase().startsWith(inputElement.value.toLowerCase())) {
              isValid = true;
              inputElement.value = option.text;
              this.data.fieldValue = option.value;
              break;
            }
          }
          if (!isValid) {
            inputElement.value = '';
            this.data.fieldValue = '';
          }
          resetButton.innerHTML = '';
        }
      }

      this.container.appendChild(inputElement);
      this.container.appendChild(resetButton);

      dataListElement = document.createElement("datalist");
      dataListElement.id = this.data.fieldName + '_7f6a3179-49a1-407f-aa52-b807e2db1110';

      this.container.appendChild(dataListElement);
      this.applyRequiredStyling(inputElement);
    }

    // Set control based on config data
    const annotState = window.AnnotationState[this.data.fieldName];
    inputElement.autocomplete = "off";
    const isLocked = annotState && annotState.IsLocked;
    if (isLocked) {
      inputElement.disabled = isLocked;
    }
    else if (Object.prototype.hasOwnProperty.call(gxElement, 'readonly')) {
      inputElement.disabled = gxElement.readonly;
    }
    else {
      inputElement.disabled = this.data.readOnly;
    }

    let htmlOptions = '';
    if (gxElement.dropDownMultiSelect) {
        inputElement.value = this.data.fieldValue;
        inputElement.disabled = true;
    }
    else {
        for (const option of options) {
            htmlOptions += `<option value='${option.value}'>${option.text}</option>`;

            // Select the current value
            if (this.data.fieldValue === option.value) {
                inputElement.value = option.text;
            }
        }
    }

    dataListElement.innerHTML = htmlOptions;
  }

  /**
   * Build the standard Dropdown Widget
   *
   * @param gxElement {Object} - The GX FieldDTO for this widget
   */
  renderDropDown(gxElement = { }) {
    let selectElement;
    const elementSearch = document.getElementsByName(this.data.fieldName);

    // If the control already exists, clear it's options so it can be reset
    if (elementSearch && elementSearch.length > 0) {
      selectElement = elementSearch[0];

      while (selectElement.firstChild) {
        selectElement.removeChild(selectElement.firstChild);
      }
    }

    // If the control doesn't exist, create it
    else {
      selectElement = document.createElement("select");
      selectElement.name = this.data.fieldName;

      // Trigger change event - The viewer is responsible for state management
      let that = this;
      selectElement.onchange = () => {
        that.applyRequiredStyling(selectElement);
        this.eventBus.dispatch("fieldchanged", {
        source: this,
      });
    }
      this.container.appendChild(selectElement);
    }

    // Set control based on config data
    const annotState = window.AnnotationState[selectElement.name];
    selectElement.autocomplete = "off";
    const isLocked = annotState && annotState.IsLocked;
    if (isLocked) {
      selectElement.disabled = isLocked;
    }
    else if (Object.prototype.hasOwnProperty.call(gxElement, 'readonly')) {
      selectElement.disabled = gxElement.readonly;
    }
    else {
      selectElement.disabled = this.data.readOnly;
    }

    if (!this.data.combo) {
      // List boxes have a size and (optionally) multiple selection.
      selectElement.size = this.data.options.length;
      if (this.data.multiSelect) {
        selectElement.multiple = true;
      }
    }

    // Insert the options into the choice field.
    let options = gxElement.options || this.data.options;

    // Add a default blank option
    selectElement.appendChild(new Option('', '', true, false));

    for (const option of options) {
      const optionElement = new Option(
        option.displayValue || option.text,
        option.exportValue || option.value,
        false,
        false
      );

      // Note: The page is scaled before the annotation layer is created,
      // so this must be initialized.
      optionElement.style.fontSize = `${120 * PDFViewerApplication.pdfViewer._currentScale}%`;

      // Set the value in view
      if (this.data.fieldValue === optionElement.value) {
        optionElement.setAttribute("selected", true);
      }

      selectElement.appendChild(optionElement);
    }
    this.applyRequiredStyling(selectElement);
  }
}

class DrawingAnnotationElement extends AnnotationElement {
  constructor(parameters) {
    super(parameters, parameters.renderInteractiveForms);
  }

  // Grab the transform matrix used by the annotation layer builder
  // and update values and DOM elements that use it.
  refreshTransforms(e, rotate = false) {
    this.transformMx =
      e.source._pages[this.data.coordinates.page - 1].viewport.transform;
    this.normalizeMx = this._getNormalizeMx(this.transformMx);

    this.txScalar =
      this.rotation === 0 || this.rotation === 180
        ? Math.abs(this.transformMx[0])
        : Math.abs(this.transformMx[1]);
    this.txRevert = 1 / this.txScalar;

    // If this Annotation is in Edit mode on, exit
    // edit mode without saving
    if (this.active) {
      this.exitEditMode(false);
    }
    // Update the positioning of the buttons on PDJS rotate
    if (rotate) {
      this._setupEditButton(this.editBtn);
      this._setupSaveButton(this.saveBtn);
      this._setupClearButton(this.clearBtn);
      this._setupUndoButton(this.undoBtn);
    }
    this.clearedDrawing = false;
  }

  /**
   * Render the Drawing widget annotation's HTML element
   * in the empty container.
   *
   * @public
   * @memberof DrawingAnnotationWidgetAnnotationElement
   * @returns {HTMLSectionElement}
   */
  render() {
    this.eventBus._on('rotationchanging', (e) => this.refreshTransforms(e, true), true);
    this.eventBus._on('scalechanging', (e) => this.refreshTransforms(e), true);
    this.init();

    // If strokes exists on load, load into the canvas
    this.previewPad = new SignaturePad(this.canvas, {
      minWidth: 0.4,
      maxWidth: 2,
    });
    const strokes = this.data.fieldValue || [];
    if (strokes.length > 0) {
      this.previewPad.fromData(strokes);
    }

    this.container.appendChild(this.wrapper);

    return this.container;
  }

  enterEditMode() {
    // Setup the wrapper
    this.wrapper.style.border = "solid 2px rgb(0, 54, 255)";
    this.wrapper.style.boxShadow = "rgba(0, 54, 255, 0.66) 0px 0px 6px 1px";
    this.wrapper.replaceChild(this.saveBtn, this.editBtn);
    this.clearBtn.style.display = this.undoBtn.style.display = "block";
    this.drawCanvas.style.cursor = "crosshair";
    this.clearedDrawing = false;
    this.active = true;
    this._toggleSubmitButton();
    this._setPointerEvents("all");

    this._setupDrawCanvas();
  }

  exitEditMode(save = true) {
    // Setup the wrapper
    this.wrapper.style.border = "dashed 2px rgba(0, 54, 255, 0.25)";
    this.wrapper.style.boxShadow = null;
    this.wrapper.replaceChild(this.editBtn, this.saveBtn);
    this.clearBtn.style.display = this.undoBtn.style.display = "none";

    // Cleanup draw canvas
    this.drawCanvas.style.cursor = "auto";
    this._setPointerEvents("none");
    this.wrapper.removeChild(this.drawCanvas);
    this.drawingPad.off();

    if (save) {
      this._saveAnnotation();
    }
    this.discardedData = [];
    this.clearedDrawing = false;
    this.active = false;
    this._toggleSubmitButton();
  }

  clearDrawing() {
    // Wipe the draw canvas and existing strokes
    this.previewPad.clear();

    // Save a backup of active strokes before clear, two vars
    // in case they hit undo twice won't overwrite
    const discardedData = this.drawingPad.toData() || [];
    if (discardedData.length) {
      this.discardedData = discardedData;
      this._resizeData(this.drawingPad.discardedData, this.txRevert, this.txRevert);
    }
    this.drawCanvas.getContext("2d").resetTransform();
    this.drawingPad.clear();
    this._normalize(this.drawCanvas);
    this.clearedDrawing = true;
  }

  // Wipe the drawing canvas but leave existing strokes
  // If clear drawings was the last action, undo it
  revertPrevious() {
    const savedStrokes = this.data.fieldValue || [];
    if (this.clearedDrawing) {
      // If last action was clearing the annotation, revert that action
      if (savedStrokes.length) {
        this.previewPad.fromData(savedStrokes);
      }
      if (this.discardedData && this.discardedData.length) {
        this.drawingPad.fromData(this.discardedData);
      }
      this.clearedDrawing = false;
      return;
    } else {
      // Else remove the last drawn stroke
      const activeStrokes = this.drawingPad.toData() || [];
      if (activeStrokes.length) {
        activeStrokes.pop();
        this.drawCanvas.getContext("2d").resetTransform();
        this.drawingPad.clear();
        this._normalize(this.drawCanvas);
        this.drawingPad.fromData(activeStrokes);
      }
    }
    this.discardedData = [];

  }

  _setupDrawCanvas(resize = false) {
    // Setup the draw canvas
    this.drawCanvas.width = this.data.coordinates.w;
    this.drawCanvas.height = this.data.coordinates.h;
    this.drawCanvas.style.width = this.container.style.width;
    this.drawCanvas.style.height = this.container.style.height;
    this.wrapper.appendChild(this.drawCanvas);

    if (!this.drawingPad) {
      this.drawingPad = new SignaturePad(this.drawCanvas, {
        penColor: "#671e75",
        minWidth: 0.4 * this.txScalar,
        maxWidth: 2 * this.txScalar,
        onEnd: event => {
          this.clearedDrawing = false;
          this._setCSSUserSelect('auto');
        },
        onBegin: event => {
          this._setCSSUserSelect('none');
        }
      });
    } else {
      this.drawingPad.minWidth = 0.4 * this.txScalar;
      this.drawingPad.maxWidth = 2 * this.txScalar;
      this.drawingPad._data = [];
      this.drawingPad.clear();
      this.drawingPad.on();
    }
    this._normalize(this.drawCanvas);
  }

  // Drop the composite of the new and existing stroke data to the
  // preview canvas and update the form and field value properties
  _saveAnnotation() {
    let newStrokes = [];
    if (!this.drawingPad.isEmpty()) {
      newStrokes = this.drawingPad.toData();
      // Scale the data from the draw canvas by the transform delta
      // so it fits to natural dimensions opposed to offset
      this._resizeData(newStrokes, this.txRevert, this.txRevert);
      this._rotateData(newStrokes, this.rotation);
    }

    // Create a composite of draw and preview canvas data
    let compositeStrokes = [];
    const previousStrokes = this.data.fieldValue || [];
    if (previousStrokes.length && !this.clearedDrawing) {
      compositeStrokes = previousStrokes.concat(newStrokes);
    } else {
      compositeStrokes = newStrokes;
    }

    this.previewPad.fromData(compositeStrokes);
    try {
      this.data.fieldValue = window.AnnotationState[this.data.fieldName].Value.Strokes = compositeStrokes;
    } catch (e) {
      console.dir(e);
    }
  }

  // Scale the canvas 2D context by recipricol scalar to compensate
  // for container offsets.  Rotate the canvas context due to the
  // same.  Set revert = true and this operation will apply the original
  // transform instead : (if desire is to undo an existing normalize).
  _normalize(canvasEl, revert = false) {
    const xOffset = revert ? 1/this.normalizeMx[0] : this.normalizeMx[0];
    const yOffset = revert ? 1/this.normalizeMx[3] : this.normalizeMx[3];
    const scalar = revert ? this.txRevert : this.txScalar;
    if (this.rotation === 0) {
      canvasEl.getContext("2d").scale(Math.abs(xOffset), Math.abs(yOffset));

    } else if (this.rotation === 90) {
      canvasEl.getContext("2d").scale(Math.abs(xOffset), Math.abs(yOffset));
      canvasEl.getContext("2d").rotate(1.5 * Math.PI);
      canvasEl.getContext("2d").translate(-this.drawCanvas.height * scalar, 0);

    } else if (this.rotation === 270) {
      canvasEl.getContext("2d").scale(Math.abs(xOffset), Math.abs(yOffset));
      canvasEl.getContext("2d").rotate(0.5 * Math.PI);
      canvasEl.getContext("2d").translate(0, -this.drawCanvas.width * scalar);

    } else if (this.rotation === 180) {
      canvasEl.getContext("2d").scale(Math.abs(xOffset), Math.abs(yOffset));
      canvasEl.getContext("2d").rotate(Math.PI);
      canvasEl.getContext("2d").translate(-this.drawCanvas.width * scalar, -this.drawCanvas.height * scalar);
    }
  }

  // Set the rotation angle derived from the viewport transfrom array, and
  // return a css transform matrix exclusively for scale (only AD ignore BCEF for now)
  _getNormalizeMx(transformMatrix) {
    // Detect the pdf page orientation and return a transform
    if (transformMatrix[0] === 0) {
      if (transformMatrix[1] > 0) {
        this.rotation = 90;
        return [ 1/transformMatrix[1], 0, 0, 1/transformMatrix[2], 0, 0, 0 ];
      } else {
        this.rotation = 270;
        return [ (1/transformMatrix[1]), 0, 0, 1/transformMatrix[2], 0, 0 ];
      }
    } else {
      if (transformMatrix[0] > 0) {
        this.rotation = 0;
        return [ 1/transformMatrix[0], 0, 0, 1/transformMatrix[3], 0, 0 ];
      } else {
        this.rotation = 180;
        return [ (1/transformMatrix[0]), 0, 0, (1/transformMatrix[3]), 0, 0 ];
      }
    }
  }

  _setupCanvas(el, draw = false) {
    el.id = draw ? `drawing_drawCanvas_${this.data.fieldName}` : `drawing_canvas_${this.data.fieldName}`;
    el.name = this.data.fieldName;
    el.width = this.data.coordinates.w;
    el.height = this.data.coordinates.h;
    el.style.width = this.data.coordinates.w;
    el.style.height = this.data.coordinates.h;
    el.style.pointerEvents = "none";
    el.style.position = "fixed";
    el.style.top = "0";
    el.style.left = "0";
  }

  _setupWrapper(el) {
    el.id = `drawing_wrapper_${this.data.fieldName}`;
    el.style.width = `${this.data.coordinates.w}px`;
    el.style.height = `${this.data.coordinates.h}px`;
    el.style.position = "relative";
    el.style.border = "dashed 2px rgba(0, 54, 255, 0.25)";
    el.style.borderRadius = "8px";
    el.style.margin = "-2px";
    el.style.pointerEvents = "none";
    el.appendChild(this.editBtn);
    el.appendChild(this.undoBtn);
    el.appendChild(this.clearBtn);
  }

  _setupContainer(el) {
    el.id = `drawing_section_${this.data.fieldName}`;
    el.width = this.data.coordinates.w;
    el.height = this.data.coordinates.h;
    el.style.width = `${this.data.coordinates.w}px`;
    el.style.height = `${this.data.coordinates.h}px`;
    el.style.top = `${this.data.coordinates.y1}px`;
    el.style.left = `${this.data.coordinates.x1}px`;
    el.style.pointerEvents = "none";
    el.style.transformOrigin = `-${this.data.coordinates.x1}px -${this.data.coordinates.y1}px`;
  }

  _resizeData(data, ratioX, ratioY) {
    if (data) {
      data.forEach(function (stroke) {
        stroke.points.forEach(function (point) {
          point.x *= ratioX;
          point.y *= ratioY;
        });
      });
    }
  }

  _rotateData(data, orientation) {
    if (data && orientation !== 0) {
      const key = orientation.toString();
      const angles = {
        0: 0,
        90: 1.5 * Math.PI,
        180: Math.PI,
        270: 0.5 * Math.PI,
      };
      const sin = Math.sin(angles[key]);
      const cos = Math.cos(angles[key]);
      data.forEach(stroke => {
        stroke.points.forEach(point => {
          switch (orientation) {
            case 90:
              point.x -= this.drawCanvas.height;
              break;
            case 180:
              point.x -= this.drawCanvas.width;
              point.y -= this.drawCanvas.height;
              break;
            case 270:
              point.y -= this.drawCanvas.width;
              break;
          }
          const xNew = point.x * cos - point.y * sin;
          const yNew = point.x * sin + point.y * cos;
          point.x = xNew;
          point.y = yNew;
        });
      });
    }
  }

  _setupEditButton(el) {
    el.id = "edit-" + this.data.id;
    el.alt = "Edit Drawing";
    el.title = "Edit Drawing";
    el.src = "images/drawing-edit-icon.svg";
    this._setButtonPosition(el, 0);
    el.style.display = this.active ? "none" : "block";
    el.onclick = () => {
      this.enterEditMode();
    };
  }

  _setupSaveButton(el) {
    el.id = "save-" + this.data.id;
    el.alt = "Save Drawing";
    el.title = "Save Drawing";
    el.src = "images/drawing-confirm-icon.svg";
    this._setButtonPosition(el, 1);
    el.onclick = () => {
      this.exitEditMode();
    };
  }

  _setupUndoButton(el) {
    el.id = "undo-" + this.data.id;
    el.alt = "Undo";
    el.title = "Undo Last";
    el.src = "images/drawing-undo-icon.svg";
    this._setButtonPosition(el, 2);
    el.style.display = this.active ? "block" : "none";
    el.onclick = () => {
      this.revertPrevious();
    };
  }

  _setupClearButton(el) {
    el.id = "clear-" + this.data.id;
    el.src = "images/drawing-erase-icon.svg";
    el.alt = "Clear Drawing";
    el.title = "Clear Drawing";
    this._setButtonPosition(el, 3);
    el.style.display = this.active ? "block" : "none";

    el.onclick = () => {
      this.clearDrawing();
    };
  }

  _setPointerEvents(value) {
    this.container.style.pointerEvents = value;
    this.wrapper.style.pointerEvents = value;
    this.drawCanvas.style.pointerEvents = value;
  }

  _setButtonPosition(el, pos) {
    let topMargin;
    switch (pos) {
      case 0:
      case 1:
        topMargin = 0;
        break;
      case 2:
        topMargin = 29;
        break;
      case 3:
        topMargin = 58;
        break;
    }
    el.width = 22;
    el.height = 22;
    el.classList.add("drawing-button");
    el.setAttribute("role", "button");
    el.setAttribute("aria-label", el.id);
    switch (this.rotation) {
      case 0:
        el.style.top = topMargin + "px";
        el.style.left = null;
        el.style.right = pos !== 0 ? "-" + (el.width + 8) + "px" : "0";
        el.style.bottom = null;
        break;
      case 90:
        el.style.top = pos !== 0 ? "-" + (el.height + 8) + "px" : "0";
        el.style.left = topMargin + "px";
        el.style.right = null;
        el.style.bottom = null;
        break;
      case 180:
        el.style.top = this.canvas.height - el.height - topMargin - 8 + "px";
        el.style.left = pos !== 0 ? "-" + (el.width + 8) + "px" : "0";
        el.style.right = null;
        el.style.bottom = null;
        break;
      case 270:
        el.style.top = pos !== 0 ? this.canvas.height + "px" : this.canvas.height - el.height - 8 + "px";
        el.style.left = null;
        el.style.right = topMargin + "px";
        el.style.bottom = null;
        break;
    }
    el.style.transform = this._setButtonRotation();
  }

  _setButtonRotation() {
    const kvps = {
      0: "rotate(0deg)",
      90: "rotate(270deg)",
      180: "rotate(180deg)",
      270: "rotate(90deg)",
    };
    return kvps[this.rotation.toString()];
  }

  _toggleSubmitButton() {
    const viewerSubmitBTN = Array.from(
      document.getElementById('toolbarViewerRight').getElementsByTagName('button')
    ).find(btn => btn.id === 'submitForm');
    if (viewerSubmitBTN) {
      viewerSubmitBTN.disabled = document.drawingAnnotations.some(anno => anno.active);
    }
  }

  _setCSSUserSelect(prop) {
    window.document.body.style.userSelect = prop;
  }

  init() {
    // Setup initial elements and data
    this.transformMx = this.viewport.transform;
    this.normalizeMx = this._getNormalizeMx(this.transformMx);

    this.canvas = document.createElement("canvas");
    this.drawCanvas = document.createElement("canvas");
    this.wrapper = document.createElement("div");
    this.editBtn = document.createElement("img");
    this.saveBtn = document.createElement("img");
    this.clearBtn = document.createElement("img");
    this.undoBtn = document.createElement("img");

    // the scale magnitude impose by zoom
    this.txScalar = (this.rotation === 0 || this.rotation === 180) ? Math.abs(this.transformMx[0]) : Math.abs(this.transformMx[1]);
    this.txRevert = 1 / this.txScalar; // the reciprocal necessary to undo the transform

    // Setup elements
    this._setupContainer(this.container);
    this._setupWrapper(this.wrapper);
    this._setupCanvas(this.canvas);
    this._setupCanvas(this.drawCanvas, true);
    this.wrapper.appendChild(this.canvas);

    // Setup buttons
    this._setupEditButton(this.editBtn);
    this._setupSaveButton(this.saveBtn);
    this._setupClearButton(this.clearBtn);
    this._setupUndoButton(this.undoBtn);
    this.active = false;
  }
}

class SignatureWidgetAnnotationElement extends WidgetAnnotationElement {
  constructor(parameters) {
    super(parameters, parameters.renderInteractiveForms);
  }

  /**
   * Render the signature widget annotation's HTML element
   * in the empty container.
   *
   * @public
   * @memberof SignatureWidgetAnnotationElement
   * @returns {HTMLSectionElement}
   */
  render() {
    if (window.parent.isEmbedded) {
      this.eventBus._on("clear-signatures", async (e) => { await this.clear() }, false);
    }
    this.container.className = "buttonWidgetAnnotation signature";
    // Clear all children
    while (this.container.firstChild) {
      this.container.removeChild(this.container.firstChild);
    }
    // Display the Signature if it exists, otherwise accept new signatures
    let element = null;
    if (this.data.fieldValue) {
      // Render the Signature
      if (this.data.fieldValue.Type === "TypedSignature") {
        element = this._renderTypedSignature();
      } else {
        element = this._renderSignature();
      }
      // Add clear signature button
      const clearEl = this._clearButton();
      this.container.appendChild(clearEl);

      const signatureFieldDTO = (window.GXData || []).find(a => a.name == this.data.fieldName);
      if (signatureFieldDTO) {
        const timestampElement = this._renderSignatureTimestamp(signatureFieldDTO);
        if (timestampElement) {
          this.container.appendChild(timestampElement);
        }
      }

      // Enable field locking since this component has been signed.
      this._lockFields(this.data.fieldLocking);
    } else {
      // Render a button on the PDF to Sign
      element = this._renderDialogButton();
    }
    this.container.appendChild(element);
    this.applyRequiredStylingForDrawnSignature(element);
    return this.container;
  }

  clear() {
    if (!this.data.fieldValue) {
      return;
    }
    // Wipe signature data
    this.data.fieldValue = null;
    window.AnnotationState[this.data.fieldName] = {
      Type: "Signature",
      Value: {
        SignedDate: "",
        Strokes: "",
        Text: ""
      }
    };

    const signatureFieldDTO = (window.GXData || []).find(a => a.name == this.data.fieldName) || { };
    if (signatureFieldDTO.signatureTimeStampEnabled && signatureFieldDTO.signatureTimeStampFieldName) {
      let tbTimeStamp = window.document.getElementsByName(signatureFieldDTO.signatureTimeStampFieldName);
      if (tbTimeStamp && tbTimeStamp.length > 0) {
        tbTimeStamp[0].value = '';
      }

      window.AnnotationState[signatureFieldDTO.signatureTimeStampFieldName].Value.Text = '';
    }

    // Reset locked fields
    this._unlockFields(this.data.fieldLocking);

    // Add back the dialog button
    const element = this._renderDialogButton();

    while (this.container.firstChild) { this.container.removeChild(this.container.firstChild); }
    this.container.appendChild(element);
    this.applyRequiredStylingForDrawnSignature(element);

    if (window.parent.isEmbedded) {
      this.eventBus.off("clear-signatures", async (e) => { await this.clear() }, false);
    }
  }

  _clearButton() {
    const element = document.createElement("img");
    element.name = this.data.fieldName;
    element.src = "images/signature-clear.svg";
    element.style.position = "fixed";
    element.style.top = "-.5em";
    element.style.right = "-.5em";
    element.style.opacity = "0.5";
    element.alt="Clear Signature";
    element.style.cursor = "pointer";

    // Pop up a confirmation dialog that will clear the sig when they press Ok.
    element.onclick = () => {
      if (!element.disabled) {
        PDFViewerApplication.confirmationPrompt.open({ labelText: "You are about to clear the signature." }, () => {
          this.clear();
        });
      }
    };
    return element;
  }

  _renderDialogButton() {
    const element = document.createElement("input");

    // Fill div
    element.style.width = "100%";
    element.style.height = "100%";

    element.type = "button";
    element.name = this.data.fieldName;
    element.value = "Click to Sign";
    element.style.cursor = element.disabled ? "not-allowed" : "pointer";

    // Note: Locking all fields will not affect the ability to sign.
    element.disabled = this.data.readOnly;

    // On-Click launch the Signature dialog
    element.onclick = () => this.eventBus.dispatch('sign', this);

    return element;
  }

  _renderSignatureTimestamp(fieldDTO) {
    const timestampValueTex = this.data.fieldValue.Value.SignedDate || '';
    if (timestampValueTex) {
      const signatureFieldDTO = fieldDTO || { };
      const timestampLocation = signatureFieldDTO.signatureTimeStampLocation || '';
      const timestampDisplayF = signatureFieldDTO.signatureTimeStampFormat || '';
      if (timestampLocation === 'bottomright') {
        const element = document.createElement("div");
        element.innerText = stringifyDate(timestampDisplayF, timestampValueTex);
        const w = this.container.style.width.substring(0, this.container.style.width.length - 2);
        const h = this.container.style.height.substring(0, this.container.style.height.length - 2);
        element.style.position = "fixed";
        element.style.textAlign = "right";
        element.style.width = (element.innerText.length * 5) + "px";
        element.style.height = "10px";
        element.style.left = +w - ((element.innerText.length * 5)) + "px";
        element.style.fontSize = "8px";
        element.style.whiteSpace = "nowrap";
        element.style.top = +h + "px";
        return element;
      }
      else if (timestampLocation === 'inlineright') {
        const element = document.createElement("div");
        element.innerText = stringifyDate(timestampDisplayF, timestampValueTex);
        const w = this.container.style.width.substring(0, this.container.style.width.length - 2);
        const h = this.container.style.height.substring(0, this.container.style.height.length - 2);
        element.style.position = "fixed";
        element.style.textAlign = "left";
        element.style.width = (element.innerText.length * 5) + "px";
        element.style.height = "10px";
        element.style.left = (+w + 4) + "px";
        element.style.top = (+h - 10) + "px";
        element.style.fontSize = "8px";
        element.style.whiteSpace = "nowrap";
        return element;
      }
    }
  }

  _renderSignature() {

    // Create Written Signature
    const element = document.createElement("canvas");
    element.name = this.data.fieldName;
    const rectangleWidth = this.data.rect[2] - this.data.rect[0];
    const rectangleHeight = this.data.rect[3] - this.data.rect[1];

    // Set the canvas element dimensions
    // and use true rectangle size in CSS
    this._scaleSig = 2;
    element.width = rectangleWidth * this._scaleSig;
    element.height = rectangleHeight * this._scaleSig;
    element.style.width = rectangleWidth + "px";
    element.style.height = rectangleHeight + "px";

    // Create a new array of strokes
    // to transform for viewing in the Viewer
    const visibleStrokes = JSON.parse(
      JSON.stringify(this.data.fieldValue.Value.Strokes)
    );
    this._scaleStrokes(visibleStrokes, element.width, element.height);

    const sigPad = new SignaturePad(element, {
      minDistance: 3,
      throttle: 8,
    });

    // Signature should never be malformed.
    // However, do not block the rendering of the form if it is.
    try {
      sigPad.fromData(visibleStrokes);
    } catch (e) {
      console.log(e);
    }

    sigPad.off();
    return element;
  }

  _getSigSize(strokes) {
    let minX = Number.MAX_VALUE;
    let minY = Number.MAX_VALUE;
    let maxX = 0;
    let maxY = 0;

    strokes.forEach(function (stroke) {
      stroke.points.forEach(function (point) {
        if (point.x < minX) {
          minX = point.x;
        }
        if (point.x > maxX) {
          maxX = point.x;
        }
        if (point.y < minY) {
          minY = point.y;
        }
        if (point.y > maxY) {
          maxY = point.y;
        }
      });
    });

    return {
      width: (maxX - minX) * this._scaleSig,
      height: (maxY - minY) * this._scaleSig,
      minX,
      minY,
      maxX,
      maxY,
    };
  }

  _scaleStrokes(strokes, natWidth, natHeight) {
    const padding = 3 * this._scaleSig;
    let scalar = 1;
    const paddedWidth = natWidth - 2 * padding;
    const height = natHeight; // Change to padded height if we ever add vert padding.
    const dataDimensions = this._getSigSize(strokes);

    // Get the scalar multiplier for how to shrink the signature
    if (dataDimensions.width > paddedWidth || dataDimensions.height > height) {
      const ratioX = paddedWidth / dataDimensions.width;
      const ratioY = height / dataDimensions.height;

      scalar = ratioX < ratioY ? ratioX : ratioY;
    } else {
      // We need to counter the effect of applying the scale below
      scalar = 1 / this._scaleSig;
    }

    // Calculate center padding - Note: This is using the un-scaled scalar
    const centerPadding = (height - dataDimensions.height * scalar) / 2;

    // Since our canvas is up-scaled, we need to expand our multiplier
    scalar *= this._scaleSig;

    // Crop the signature, scale it, augment with padding
    strokes.forEach(stroke => {
      stroke.points.forEach(point => {
        point.x = (point.x - dataDimensions.minX) * scalar + padding;
        point.y = (point.y - dataDimensions.minY) * scalar + centerPadding;
      });
    });
  }

  _renderTypedSignature() {
    // Create Typed Signature
    const element = document.createElement("canvas");
    element.name = this.data.fieldName;
    const rectangleWidth = this.data.rect[2] - this.data.rect[0];
    const rectangleHeight = this.data.rect[3] - this.data.rect[1];

    // Set the canvas element dimensions
    // and use true rectangle size in CSS
    this._scaleSig = 2;
    element.width = rectangleWidth * this._scaleSig;
    element.height = rectangleHeight * this._scaleSig;
    element.style.width = rectangleWidth + "px";
    element.style.height = rectangleHeight + "px";
    const ctx = element.getContext("2d");
    ctx.font = "normal normal 48px 'Brush Script MT', cursive";
    ctx.fillText(
      this.data.fieldValue.Value.Text,
      10,
      element.height - 10,
      element.width - 20
    );
    return element;
  }

  _processFieldLocking(fieldLocking, lockFields) {

    if (!fieldLocking) return;

    // Unlock all fields based on lockin strategy
    for (const fieldName in window.AnnotationState) {
      if (!window.AnnotationState.hasOwnProperty(fieldName)) continue;

      let targetted = false;

      switch (fieldLocking.type){
        case "ALL":
          targetted = true;
          break;
        case "EXCEPT":
          targetted = !fieldLocking.fields.includes(fieldName);
          break;
        case "THESE":
          targetted = fieldLocking.fields.includes(fieldName);
          break;
      }

      if (targetted) {
        // Set the field element in the DOM, if rendered
        const fieldEl = document.getElementsByName(fieldName)[0];
        if (fieldEl) {
          fieldEl.disabled = lockFields;
          if (Object.values(fieldEl.parentNode.classList).includes("signature")) {
            fieldEl.style.visibility = lockFields ? "hidden" : "visible";
          }
        }

        // Update the state, for those not yet rendered
        const field = window.AnnotationState[fieldName];
        if (field) {
          field.IsLocked = lockFields;
        }
      }
    }
  } // Func: _processFieldLocking(...)

  _lockFields(fieldLocking) {
    this._processFieldLocking(fieldLocking, true);
  }

  _unlockFields(fieldLocking) {
    this._processFieldLocking(fieldLocking, false);
  }
}

class PopupAnnotationElement extends AnnotationElement {
  constructor(parameters) {
    const isRenderable = !!(parameters.data.title || parameters.data.contents);
    super(parameters, isRenderable);
  }

  /**
   * Render the popup annotation's HTML element in the empty container.
   *
   * @public
   * @memberof PopupAnnotationElement
   * @returns {HTMLSectionElement}
   */
  render() {
    // Do not render popup annotations for parent elements with these types as
    // they create the popups themselves (because of custom trigger divs).
    const IGNORE_TYPES = [
      "Line",
      "Square",
      "Circle",
      "PolyLine",
      "Polygon",
      "Ink",
    ];

    this.container.className = "popupAnnotation";

    if (IGNORE_TYPES.includes(this.data.parentType)) {
      return this.container;
    }

    const selector = `[data-annotation-id="${this.data.parentId}"]`;
    const parentElement = this.layer.querySelector(selector);
    if (!parentElement) {
      return this.container;
    }

    const popup = new PopupElement({
      container: this.container,
      trigger: parentElement,
      color: this.data.color,
      title: this.data.title,
      modificationDate: this.data.modificationDate,
      contents: this.data.contents,
    });

    // Position the popup next to the parent annotation's container.
    // PDF viewers ignore a popup annotation's rectangle.
    const parentLeft = parseFloat(parentElement.style.left);
    const parentWidth = parseFloat(parentElement.style.width);
    this.container.style.transformOrigin = `-${parentLeft + parentWidth}px -${
      parentElement.style.top
    }`;
    this.container.style.left = `${parentLeft + parentWidth}px`;

    this.container.appendChild(popup.render());
    return this.container;
  }
}

class PopupElement {
  constructor(parameters) {
    this.container = parameters.container;
    this.trigger = parameters.trigger;
    this.color = parameters.color;
    this.title = parameters.title;
    this.modificationDate = parameters.modificationDate;
    this.contents = parameters.contents;
    this.hideWrapper = parameters.hideWrapper || false;

    this.pinned = false;
  }

  /**
   * Render the popup's HTML element.
   *
   * @public
   * @memberof PopupElement
   * @returns {HTMLSectionElement}
   */
  render() {
    const BACKGROUND_ENLIGHT = 0.7;

    const wrapper = document.createElement("div");
    wrapper.className = "popupWrapper";

    // For Popup annotations we hide the entire section because it contains
    // only the popup. However, for Text annotations without a separate Popup
    // annotation, we cannot hide the entire container as the image would
    // disappear too. In that special case, hiding the wrapper suffices.
    this.hideElement = this.hideWrapper ? wrapper : this.container;
    this.hideElement.setAttribute("hidden", true);

    const popup = document.createElement("div");
    popup.className = "popup";

    const color = this.color;
    if (color) {
      // Enlighten the color.
      const r = BACKGROUND_ENLIGHT * (255 - color[0]) + color[0];
      const g = BACKGROUND_ENLIGHT * (255 - color[1]) + color[1];
      const b = BACKGROUND_ENLIGHT * (255 - color[2]) + color[2];
      popup.style.backgroundColor = Util.makeCssRgb(r | 0, g | 0, b | 0);
    }

    const title = document.createElement("h1");
    title.textContent = this.title;
    popup.appendChild(title);

    // The modification date is shown in the popup instead of the creation
    // date if it is available and can be parsed correctly, which is
    // consistent with other viewers such as Adobe Acrobat.
    const dateObject = PDFDateString.toDateObject(this.modificationDate);
    if (dateObject) {
      const modificationDate = document.createElement("span");
      modificationDate.textContent = "{{date}}, {{time}}";
      modificationDate.dataset.l10nId = "annotation_date_string";
      modificationDate.dataset.l10nArgs = JSON.stringify({
        date: dateObject.toLocaleDateString(),
        time: dateObject.toLocaleTimeString(),
      });
      popup.appendChild(modificationDate);
    }

    const contents = this._formatContents(this.contents);
    popup.appendChild(contents);

    // Attach the event listeners to the trigger element.
    this.trigger.addEventListener("click", this._toggle.bind(this));
    this.trigger.addEventListener("mouseover", this._show.bind(this, false));
    this.trigger.addEventListener("mouseout", this._hide.bind(this, false));
    popup.addEventListener("click", this._hide.bind(this, true));

    wrapper.appendChild(popup);
    return wrapper;
  }

  /**
   * Format the contents of the popup by adding newlines where necessary.
   *
   * @private
   * @param {string} contents
   * @memberof PopupElement
   * @returns {HTMLParagraphElement}
   */
  _formatContents(contents) {
    const p = document.createElement("p");
    const lines = contents.split(/(?:\r\n?|\n)/);
    for (let i = 0, ii = lines.length; i < ii; ++i) {
      const line = lines[i];
      p.appendChild(document.createTextNode(line));
      if (i < ii - 1) {
        p.appendChild(document.createElement("br"));
      }
    }
    return p;
  }

  /**
   * Toggle the visibility of the popup.
   *
   * @private
   * @memberof PopupElement
   */
  _toggle() {
    if (this.pinned) {
      this._hide(true);
    } else {
      this._show(true);
    }
  }

  /**
   * Show the popup.
   *
   * @private
   * @param {boolean} pin
   * @memberof PopupElement
   */
  _show(pin = false) {
    if (pin) {
      this.pinned = true;
    }
    if (this.hideElement.hasAttribute("hidden")) {
      this.hideElement.removeAttribute("hidden");
      this.container.style.zIndex += 1;
    }
  }

  /**
   * Hide the popup.
   *
   * @private
   * @param {boolean} unpin
   * @memberof PopupElement
   */
  _hide(unpin = true) {
    if (unpin) {
      this.pinned = false;
    }
    if (!this.hideElement.hasAttribute("hidden") && !this.pinned) {
      this.hideElement.setAttribute("hidden", true);
      this.container.style.zIndex -= 1;
    }
  }
}

class FreeTextAnnotationElement extends AnnotationElement {
  constructor(parameters) {
    const isRenderable = !!(
      parameters.data.hasPopup ||
      parameters.data.title ||
      parameters.data.contents
    );
    super(parameters, isRenderable, /* ignoreBorder = */ true);
  }

  /**
   * Render the free text annotation's HTML element in the empty container.
   *
   * @public
   * @memberof FreeTextAnnotationElement
   * @returns {HTMLSectionElement}
   */
  render() {
    this.container.className = "freeTextAnnotation";

    if (!this.data.hasPopup) {
      this._createPopup(this.container, null, this.data);
    }
    return this.container;
  }
}

class LineAnnotationElement extends AnnotationElement {
  constructor(parameters) {
    const isRenderable = !!(
      parameters.data.hasPopup ||
      parameters.data.title ||
      parameters.data.contents
    );
    super(parameters, isRenderable, /* ignoreBorder = */ true);
  }

  /**
   * Render the line annotation's HTML element in the empty container.
   *
   * @public
   * @memberof LineAnnotationElement
   * @returns {HTMLSectionElement}
   */
  render() {
    this.container.className = "lineAnnotation";

    // Create an invisible line with the same starting and ending coordinates
    // that acts as the trigger for the popup. Only the line itself should
    // trigger the popup, not the entire container.
    const data = this.data;
    const width = data.rect[2] - data.rect[0];
    const height = data.rect[3] - data.rect[1];
    const svg = this.svgFactory.create(width, height);

    // PDF coordinates are calculated from a bottom left origin, so transform
    // the line coordinates to a top left origin for the SVG element.
    const line = this.svgFactory.createElement("svg:line");
    line.setAttribute("x1", data.rect[2] - data.lineCoordinates[0]);
    line.setAttribute("y1", data.rect[3] - data.lineCoordinates[1]);
    line.setAttribute("x2", data.rect[2] - data.lineCoordinates[2]);
    line.setAttribute("y2", data.rect[3] - data.lineCoordinates[3]);
    // Ensure that the 'stroke-width' is always non-zero, since otherwise it
    // won't be possible to open/close the popup (note e.g. issue 11122).
    line.setAttribute("stroke-width", data.borderStyle.width || 1);
    line.setAttribute("stroke", "transparent");

    svg.appendChild(line);
    this.container.append(svg);

    // Create the popup ourselves so that we can bind it to the line instead
    // of to the entire container (which is the default).
    this._createPopup(this.container, line, data);

    return this.container;
  }
}

class SquareAnnotationElement extends AnnotationElement {
  constructor(parameters) {
    const isRenderable = !!(
      parameters.data.hasPopup ||
      parameters.data.title ||
      parameters.data.contents
    );
    super(parameters, isRenderable, /* ignoreBorder = */ true);
  }

  /**
   * Render the square annotation's HTML element in the empty container.
   *
   * @public
   * @memberof SquareAnnotationElement
   * @returns {HTMLSectionElement}
   */
  render() {
    this.container.className = "squareAnnotation";

    // Create an invisible square with the same rectangle that acts as the
    // trigger for the popup. Only the square itself should trigger the
    // popup, not the entire container.
    const data = this.data;
    const width = data.rect[2] - data.rect[0];
    const height = data.rect[3] - data.rect[1];
    const svg = this.svgFactory.create(width, height);

    // The browser draws half of the borders inside the square and half of
    // the borders outside the square by default. This behavior cannot be
    // changed programmatically, so correct for that here.
    const borderWidth = data.borderStyle.width;
    const square = this.svgFactory.createElement("svg:rect");
    square.setAttribute("x", borderWidth / 2);
    square.setAttribute("y", borderWidth / 2);
    square.setAttribute("width", width - borderWidth);
    square.setAttribute("height", height - borderWidth);
    // Ensure that the 'stroke-width' is always non-zero, since otherwise it
    // won't be possible to open/close the popup (note e.g. issue 11122).
    square.setAttribute("stroke-width", borderWidth || 1);
    square.setAttribute("stroke", "transparent");
    square.setAttribute("fill", "none");

    svg.appendChild(square);
    this.container.append(svg);

    // Create the popup ourselves so that we can bind it to the square instead
    // of to the entire container (which is the default).
    this._createPopup(this.container, square, data);

    return this.container;
  }
}

class CircleAnnotationElement extends AnnotationElement {
  constructor(parameters) {
    const isRenderable = !!(
      parameters.data.hasPopup ||
      parameters.data.title ||
      parameters.data.contents
    );
    super(parameters, isRenderable, /* ignoreBorder = */ true);
  }

  /**
   * Render the circle annotation's HTML element in the empty container.
   *
   * @public
   * @memberof CircleAnnotationElement
   * @returns {HTMLSectionElement}
   */
  render() {
    this.container.className = "circleAnnotation";

    // Create an invisible circle with the same ellipse that acts as the
    // trigger for the popup. Only the circle itself should trigger the
    // popup, not the entire container.
    const data = this.data;
    const width = data.rect[2] - data.rect[0];
    const height = data.rect[3] - data.rect[1];
    const svg = this.svgFactory.create(width, height);

    // The browser draws half of the borders inside the circle and half of
    // the borders outside the circle by default. This behavior cannot be
    // changed programmatically, so correct for that here.
    const borderWidth = data.borderStyle.width;
    const circle = this.svgFactory.createElement("svg:ellipse");
    circle.setAttribute("cx", width / 2);
    circle.setAttribute("cy", height / 2);
    circle.setAttribute("rx", width / 2 - borderWidth / 2);
    circle.setAttribute("ry", height / 2 - borderWidth / 2);
    // Ensure that the 'stroke-width' is always non-zero, since otherwise it
    // won't be possible to open/close the popup (note e.g. issue 11122).
    circle.setAttribute("stroke-width", borderWidth || 1);
    circle.setAttribute("stroke", "transparent");
    circle.setAttribute("fill", "none");

    svg.appendChild(circle);
    this.container.append(svg);

    // Create the popup ourselves so that we can bind it to the circle instead
    // of to the entire container (which is the default).
    this._createPopup(this.container, circle, data);

    return this.container;
  }
}

class PolylineAnnotationElement extends AnnotationElement {
  constructor(parameters) {
    const isRenderable = !!(
      parameters.data.hasPopup ||
      parameters.data.title ||
      parameters.data.contents
    );
    super(parameters, isRenderable, /* ignoreBorder = */ true);

    this.containerClassName = "polylineAnnotation";
    this.svgElementName = "svg:polyline";
  }

  /**
   * Render the polyline annotation's HTML element in the empty container.
   *
   * @public
   * @memberof PolylineAnnotationElement
   * @returns {HTMLSectionElement}
   */
  render() {
    this.container.className = this.containerClassName;

    // Create an invisible polyline with the same points that acts as the
    // trigger for the popup. Only the polyline itself should trigger the
    // popup, not the entire container.
    const data = this.data;
    const width = data.rect[2] - data.rect[0];
    const height = data.rect[3] - data.rect[1];
    const svg = this.svgFactory.create(width, height);

    // Convert the vertices array to a single points string that the SVG
    // polyline element expects ("x1,y1 x2,y2 ..."). PDF coordinates are
    // calculated from a bottom left origin, so transform the polyline
    // coordinates to a top left origin for the SVG element.
    let points = [];
    for (const coordinate of data.vertices) {
      const x = coordinate.x - data.rect[0];
      const y = data.rect[3] - coordinate.y;
      points.push(x + "," + y);
    }
    points = points.join(" ");

    const polyline = this.svgFactory.createElement(this.svgElementName);
    polyline.setAttribute("points", points);
    // Ensure that the 'stroke-width' is always non-zero, since otherwise it
    // won't be possible to open/close the popup (note e.g. issue 11122).
    polyline.setAttribute("stroke-width", data.borderStyle.width || 1);
    polyline.setAttribute("stroke", "transparent");
    polyline.setAttribute("fill", "none");

    svg.appendChild(polyline);
    this.container.append(svg);

    // Create the popup ourselves so that we can bind it to the polyline
    // instead of to the entire container (which is the default).
    this._createPopup(this.container, polyline, data);

    return this.container;
  }
}

class PolygonAnnotationElement extends PolylineAnnotationElement {
  constructor(parameters) {
    // Polygons are specific forms of polylines, so reuse their logic.
    super(parameters);

    this.containerClassName = "polygonAnnotation";
    this.svgElementName = "svg:polygon";
  }
}

class CaretAnnotationElement extends AnnotationElement {
  constructor(parameters) {
    const isRenderable = !!(
      parameters.data.hasPopup ||
      parameters.data.title ||
      parameters.data.contents
    );
    super(parameters, isRenderable, /* ignoreBorder = */ true);
  }

  /**
   * Render the caret annotation's HTML element in the empty container.
   *
   * @public
   * @memberof CaretAnnotationElement
   * @returns {HTMLSectionElement}
   */
  render() {
    this.container.className = "caretAnnotation";

    if (!this.data.hasPopup) {
      this._createPopup(this.container, null, this.data);
    }
    return this.container;
  }
}

class InkAnnotationElement extends AnnotationElement {
  constructor(parameters) {
    const isRenderable = !!(
      parameters.data.hasPopup ||
      parameters.data.title ||
      parameters.data.contents
    );
    super(parameters, isRenderable, /* ignoreBorder = */ true);

    this.containerClassName = "inkAnnotation";

    // Use the polyline SVG element since it allows us to use coordinates
    // directly and to draw both straight lines and curves.
    this.svgElementName = "svg:polyline";
  }

  /**
   * Render the ink annotation's HTML element in the empty container.
   *
   * @public
   * @memberof InkAnnotationElement
   * @returns {HTMLSectionElement}
   */
  render() {
    this.container.className = this.containerClassName;

    // Create an invisible polyline with the same points that acts as the
    // trigger for the popup.
    const data = this.data;
    const width = data.rect[2] - data.rect[0];
    const height = data.rect[3] - data.rect[1];
    const svg = this.svgFactory.create(width, height);

    for (const inkList of data.inkLists) {
      // Convert the ink list to a single points string that the SVG
      // polyline element expects ("x1,y1 x2,y2 ..."). PDF coordinates are
      // calculated from a bottom left origin, so transform the polyline
      // coordinates to a top left origin for the SVG element.
      let points = [];
      for (const coordinate of inkList) {
        const x = coordinate.x - data.rect[0];
        const y = data.rect[3] - coordinate.y;
        points.push(`${x},${y}`);
      }
      points = points.join(" ");

      const polyline = this.svgFactory.createElement(this.svgElementName);
      polyline.setAttribute("points", points);
      // Ensure that the 'stroke-width' is always non-zero, since otherwise it
      // won't be possible to open/close the popup (note e.g. issue 11122).
      polyline.setAttribute("stroke-width", data.borderStyle.width || 1);
      polyline.setAttribute("stroke", "transparent");
      polyline.setAttribute("fill", "none");

      // Create the popup ourselves so that we can bind it to the polyline
      // instead of to the entire container (which is the default).
      this._createPopup(this.container, polyline, data);

      svg.appendChild(polyline);
    }

    this.container.append(svg);
    return this.container;
  }
}

class HighlightAnnotationElement extends AnnotationElement {
  constructor(parameters) {
    const isRenderable = !!(
      parameters.data.hasPopup ||
      parameters.data.title ||
      parameters.data.contents
    );
    super(parameters, isRenderable, /* ignoreBorder = */ true);
  }

  /**
   * Render the highlight annotation's HTML element in the empty container.
   *
   * @public
   * @memberof HighlightAnnotationElement
   * @returns {HTMLSectionElement}
   */
  render() {
    this.container.className = "highlightAnnotation";

    if (!this.data.hasPopup) {
      this._createPopup(this.container, null, this.data);
    }
    return this.container;
  }
}

class UnderlineAnnotationElement extends AnnotationElement {
  constructor(parameters) {
    const isRenderable = !!(
      parameters.data.hasPopup ||
      parameters.data.title ||
      parameters.data.contents
    );
    super(parameters, isRenderable, /* ignoreBorder = */ true);
  }

  /**
   * Render the underline annotation's HTML element in the empty container.
   *
   * @public
   * @memberof UnderlineAnnotationElement
   * @returns {HTMLSectionElement}
   */
  render() {
    this.container.className = "underlineAnnotation";

    if (!this.data.hasPopup) {
      this._createPopup(this.container, null, this.data);
    }
    return this.container;
  }
}

class SquigglyAnnotationElement extends AnnotationElement {
  constructor(parameters) {
    const isRenderable = !!(
      parameters.data.hasPopup ||
      parameters.data.title ||
      parameters.data.contents
    );
    super(parameters, isRenderable, /* ignoreBorder = */ true);
  }

  /**
   * Render the squiggly annotation's HTML element in the empty container.
   *
   * @public
   * @memberof SquigglyAnnotationElement
   * @returns {HTMLSectionElement}
   */
  render() {
    this.container.className = "squigglyAnnotation";

    if (!this.data.hasPopup) {
      this._createPopup(this.container, null, this.data);
    }
    return this.container;
  }
}

class StrikeOutAnnotationElement extends AnnotationElement {
  constructor(parameters) {
    const isRenderable = !!(
      parameters.data.hasPopup ||
      parameters.data.title ||
      parameters.data.contents
    );
    super(parameters, isRenderable, /* ignoreBorder = */ true);
  }

  /**
   * Render the strikeout annotation's HTML element in the empty container.
   *
   * @public
   * @memberof StrikeOutAnnotationElement
   * @returns {HTMLSectionElement}
   */
  render() {
    this.container.className = "strikeoutAnnotation";

    if (!this.data.hasPopup) {
      this._createPopup(this.container, null, this.data);
    }
    return this.container;
  }
}

class StampAnnotationElement extends AnnotationElement {
  constructor(parameters) {
    const isRenderable = !!(
      parameters.data.hasPopup ||
      parameters.data.title ||
      parameters.data.contents
    );
    super(parameters, isRenderable, /* ignoreBorder = */ true);
  }

  /**
   * Render the stamp annotation's HTML element in the empty container.
   *
   * @public
   * @memberof StampAnnotationElement
   * @returns {HTMLSectionElement}
   */
  render() {
    this.container.className = "stampAnnotation";

    if (!this.data.hasPopup) {
      this._createPopup(this.container, null, this.data);
    }
    return this.container;
  }
}

class FileAttachmentAnnotationElement extends AnnotationElement {
  constructor(parameters) {
    super(parameters, /* isRenderable = */ true);

    const { filename, content } = this.data.file;
    this.filename = getFilenameFromUrl(filename);
    this.content = content;

    if (this.linkService.eventBus) {
      this.linkService.eventBus.dispatch("fileattachmentannotation", {
        source: this,
        id: stringToPDFString(filename),
        filename,
        content,
      });
    }
  }

  /**
   * Render the file attachment annotation's HTML element in the empty
   * container.
   *
   * @public
   * @memberof FileAttachmentAnnotationElement
   * @returns {HTMLSectionElement}
   */
  render() {
    this.container.className = "fileAttachmentAnnotation";

    const trigger = document.createElement("div");
    trigger.style.height = this.container.style.height;
    trigger.style.width = this.container.style.width;
    trigger.addEventListener("dblclick", this._download.bind(this));

    if (!this.data.hasPopup && (this.data.title || this.data.contents)) {
      this._createPopup(this.container, trigger, this.data);
    }

    this.container.appendChild(trigger);
    return this.container;
  }

  /**
   * Download the file attachment associated with this annotation.
   *
   * @private
   * @memberof FileAttachmentAnnotationElement
   */
  _download() {
    if (!this.downloadManager) {
      warn("Download cannot be started due to unavailable download manager");
      return;
    }
    this.downloadManager.downloadData(this.content, this.filename, "");
  }
}

/**
 * @typedef {Object} AnnotationLayerParameters
 * @property {PageViewport} viewport
 * @property {HTMLDivElement} div
 * @property {Array} annotations
 * @property {PDFPage} page
 * @property {IPDFLinkService} linkService
 * @property {DownloadManager} downloadManager
 * @property {string} [imageResourcesPath] - Path for image resources, mainly
 *   for annotation icons. Include trailing slash.
 * @property {boolean} renderInteractiveForms
 * @property {EventBus} eventBus - The application event bus.
 */

class AnnotationLayer {
  /**
   * Render a new annotation layer with all annotation elements.
   *
   * @public
   * @param {AnnotationLayerParameters} parameters
   * @memberof AnnotationLayer
   */
  static render(parameters) {
    if (!document.drawingAnnotations) {
      document.drawingAnnotations = [];
    }

    const sortedAnnotations = [],
      popupAnnotations = [];

    // Ensure that Popup annotations are handled last, since they're dependant
    // upon the parent annotation having already been rendered (please refer to
    // the `PopupAnnotationElement.render` method); fixes issue 11362.
    for (const data of parameters.annotations) {
      if (!data) {
        continue;
      }
      if (data.annotationType === AnnotationType.POPUP) {
        popupAnnotations.push(data);
        continue;
      }
      sortedAnnotations.push(data);
    }
    if (popupAnnotations.length) {
      sortedAnnotations.push(...popupAnnotations);
    }

    for (const data of sortedAnnotations) {
      const element = AnnotationElementFactory.create({
        data,
        layer: parameters.div,
        page: parameters.page,
        viewport: parameters.viewport,
        linkService: parameters.linkService,
        downloadManager: parameters.downloadManager,
        imageResourcesPath: parameters.imageResourcesPath || "",
        renderInteractiveForms: parameters.renderInteractiveForms || false,
        svgFactory: new DOMSVGFactory(),
        eventBus: parameters.eventBus,
      });
      if (element.isRenderable) {
        if (element instanceof DrawingAnnotationElement) {
          document.drawingAnnotations.push(element);
        }
        parameters.div.appendChild(element.render());
      }
    }
    if (document.drawingAnnotations.length > 0) {
      document.addEventListener("click", annotationValidation);
    } else {
      document.removeEventListener("click", annotationValidation);
    }
  }

  /**
   * Update the annotation elements on existing annotation layer.
   *
   * @public
   * @param {AnnotationLayerParameters} parameters
   * @memberof AnnotationLayer
   */
  static update(parameters) {
    for (const data of parameters.annotations) {
      const element = parameters.div.querySelector(
        `[data-annotation-id="${data.id}"]`
      );
      if (element) {
        element.style.transform = `matrix(${parameters.viewport.transform.join(
          ","
        )})`;
      }
    }
    parameters.div.removeAttribute("hidden");
  }
}

function annotationValidation(event) {
  for (let i=0; i < document.drawingAnnotations.length; i++) {
    if (document.drawingAnnotations[i].active) {
      if ((event.target.name && event.target.name === document.drawingAnnotations[i].data.id) ||
        event.srcElement.id === 'clear-' + document.drawingAnnotations[i].data.id ||
        event.srcElement.id === 'undo-' + document.drawingAnnotations[i].data.id ||
        event.srcElement.id === 'edit-' + document.drawingAnnotations[i].data.id ||
        event.srcElement.id === 'save-' + document.drawingAnnotations[i].data.id) {
          document.drawingAnnotations[i].wrapper.style.border = "solid 2px rgb(0, 54, 255)";
          document.drawingAnnotations[i].wrapper.style.boxShadow = "rgba(0, 54, 255, 0.66) 0px 0px 6px 1px";
      } else {
        document.drawingAnnotations[i].wrapper.style.border = '2px solid red';
        document.drawingAnnotations[i].wrapper.style.boxShadow = null;
      }
    }
  }
}

export { AnnotationLayer };
